:root {
	--horizPadding: 0%;
	--bg: #101010;
	--bg2: #303030;
	--fg: white;
	--accent: #009595;
	--link: dodgerblue;
	--dim: gray;
}

@media (prefers-color-scheme: light) {
	:root {
		--bg: #dfdfdf;
		--bg2: white;
		--fg: black;
	}
}

html {
	overflow-x: hidden;
	overflow-y: scroll;
	scroll-behavior: smooth;
}

body {
	font-family: "Playfair Display", serif;
	background-color: var(--bg);
	color: var(--fg);
	text-align: center;
}

.unsupported {
	display: block;
	margin-top: 1em;
}

a {
	color: var(--link);
	text-decoration: none;
}

.canHover a:hover {text-decoration: underline;}

button {cursor: pointer;}
button[disabled] {cursor: inherit;}

header, main, footer {padding: 1em calc(1em + var(--horizPadding));}

#headerContainer {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
}

header {
	background-color: var(--bg2);
	height: 2em;
	display: flex;
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5);
}

header, header nav {align-items: center;}

header nav {display: none;}

#logoLink {
	fill: var(--fg);
	margin: 0 1em;
	transition: fill 0.2s;
	flex-shrink: 0;
}

#logoLink svg {
	height: 2em;
	vertical-align: middle;
}

.canHover #logoLink:hover {fill: var(--accent);}

.headerSpacer {flex-grow: 1;}

nav a, nav button {
	display: block;
	color: inherit;
	transition: color 0.2s;
	padding: 0 1em;
	background-color: transparent;
	border-style: none;
}

.canHover nav a:hover, .canHover nav button:hover {
	color: var(--accent);
	text-decoration: none;
}

nav a.active, .canHover nav a.active:hover, nav button.active, .canHover nav button.active:hover {
	color: var(--accent);
	text-decoration: underline;
}

header nav a, header nav button {
	line-height: 2em;
	white-space: nowrap;
}

nav button svg {margin-left: 0.5em;}

header .navSubmenuContainer {position: relative;}

.navSubmenu {overflow: hidden;}

header .navSubmenu {
	position: absolute;
	background-color: var(--bg2);
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5);
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
}

header .navSubmenu a, header .navSubmenu button {
	padding: 0.5em 1em;
	line-height: initial;
}

#hamburgerButton {
	margin-right: 1em;
	height: 2em;
	width: 2em;
	border-style: none;
	color: inherit;
	background-color: inherit;
	padding: 0;
	transition: color 0.2s;
}

#hamburgerButton svg {font-size: 1.5em;}
.canHover #hamburgerButton:hover {color: var(--accent);}

#hamburgerMenu {
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s;
	color: var(--fg);
	background-color: var(--bg2);
	padding: 1px 1em 1em;
	position: fixed;
	top: calc(4em - 1px);
	bottom: 0;
	left: 0;
	right: 0;
	overflow-y: auto;
	overscroll-behavior: contain;
}

#hamburgerMenu.show {
	opacity: 1;
	pointer-events: inherit;
}

#hamburgerMenu nav a, #hamburgerMenu nav button {
	padding: 1em;
	box-sizing: border-box;
	width: 100%;
}

#hamburgerMenu .navSubmenu a {padding: 0.5em 1em;}

#hamburgerMenu::backdrop {background-color: transparent;}

.loader {margin: 2em 0;}
.loader svg {margin-right: 0.5em;}

hr {border: 1px solid var(--dim);}

abbr[title] {
	text-decoration: underline dotted;
	text-decoration-color: var(--dim);
	cursor: help;
}

address {font-style: inherit;}

.topHr {margin: 0 calc(1em + var(--horizPadding));}
footer {color: var(--dim);}

@media (min-width: 676px) and (min-height: 403px) { /* min-height = (37.6 * numCategories) + 64 */
	header nav {display: flex;}
	#hamburgerButton {display: none;}
}

@media (min-width: 1024px) {
	:root {--horizPadding: 10%;}
}
