.text {
	text-align: left;
	margin: 0 1em;
}

.text > p {text-indent: 0.5in;}

figure {font-style: italic;}
blockquote, blockquote p {margin-bottom: 0;}
blockquote p::before { content: '\201C';}
blockquote p::after {content: '\201D';}
figcaption {text-align: right;}

figure > * {
	margin-left: 0;
	margin-right: 0;
}

@media (min-width: 752px) {
	figure > * {
		margin-left: 40px;
		margin-right: 40px;
	}
}

img.contactPageImage {
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5);
	max-width: 100%;
	max-height: 320px;
	display: inline-block;

}
