/* depends upon gallery.css for more general stuff */

.exhibitList {
	margin: 2em;
}

article.exhibit {
	width: 100%;
	padding: 1em;
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5);
	margin-top: 1em;
	text-align: left;
	background-color: var(--bg2);
}

article.exhibit p.exhibitDates {margin: 0 0 1em;}

article.exhibit .exhibitImages {
	background-color: var(--bg);
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5) inset;
	padding: 1em;
	display: flex;
	gap: 1em;
	overflow-x: auto;
}

article.exhibit .exhibitImages img {
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5);
	max-width: calc(100% - 1em);
}
