#headings > * {margin: 16px 0;}

h1 {
	font-size: 2.5em;
}

h1 .name {
	font-family: "Yellowtail", cursive;
	font-weight: normal;
}

h1 .role {
	color: var(--dim);
	font-size: 0.5em;
	font-weight: normal;
}

h2 {font-style: italic;}
