/* For the gallery views and lightbox, as well as the homepage category list */

.galleryLoader {margin: 2em 0;}
.galleryLoader svg {margin-right: 0.5em;}

.gallery {
	margin: 2em;
	transition: opacity 0.5s;
}

.instruction {
	color: var(--dim);
}

.gallerySizer {width: 100%;}

article {
	box-sizing: border-box;
	padding: 2em;
	width: 100%;
}

article button, article.category img, article .details, article.category a {
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5);
}

article button {
	display: inline;
	padding: 0;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	line-height: 0;
}

article img {
	max-width: 100%;
	max-height: 320px;
	display: inline-block;
}

article .details {
	padding: 1em;
	margin-top: 1em;
	text-align: left;
	background-color: var(--bg2);
}

article .details cite {
	font-weight: bold;
	font-style: italic;
}

article .details h3 {
	font-weight: initial;
	margin: 0;
}

article .details p {margin: 1em 0 0;}

article.category a {
	display: inline-block;
	margin-top: 1em;
	padding: 1em 1.5em;
	font-weight: bold;
	background-color: transparent;
	border: 2px solid var(--accent);
	color: var(--fg);
	transition: background-color 0.2s, color 0.2s;
}

.canHover article.category a:hover {
	text-decoration: none;
	background-color: var(--accent);
	color: white;
}

dialog {
	position: fixed;
	background-color: var(--bg2);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	border-style: none;
	padding: 0;
	box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.5);
}

dialog img {
	cursor: pointer;
	display: block;
	max-width: calc(100vw - 4em);
	max-height: calc(100vh - 4em);
}

dialog::backdrop {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 752px) {
	.gallerySizer {width: 50%;}
	article {width: 50%;}
	article.wide {width: 100%;}
}
